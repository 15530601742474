const HiraganaDakuten = {
  name: 'Hiragana Dakuten/Handakuten',
  description: 'Dakuten and handakuten are marks that indicate a change in pronunciation.',
  icon: 'び',
  group: 1,
  characters: {
    が: [ 'ga' ],
    ぎ: [ 'gi' ],
    ぐ: [ 'gu' ],
    げ: [ 'ge' ],
    ご: [ 'go' ],
    ざ: [ 'za' ],
    じ: [ 'ji' ],
    ず: [ 'zu' ],
    ぜ: [ 'ze' ],
    ぞ: [ 'zo' ],
    だ: [ 'da' ],
    ぢ: [ 'ji' ],
    づ: [ 'zu' ],
    で: [ 'de' ],
    ど: [ 'do' ],
    ば: [ 'ba' ],
    び: [ 'bi' ],
    ぶ: [ 'bu' ],
    べ: [ 'be' ],
    ぼ: [ 'bo' ],
    ぱ: [ 'pa' ],
    ぴ: [ 'pi' ],
    ぷ: [ 'pu' ],
    ぺ: [ 'pe' ],
    ぽ: [ 'po' ],
  }
}

export default HiraganaDakuten