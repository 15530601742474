import React from 'react'

const Header = () =>
  <header className="header">
    <h1>
      Japanese
      <span>Practice</span>
    </h1>
  </header>

export default Header