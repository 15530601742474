const KatakanaCombos = {
  name: 'Katakana Combos',
  description: 'Two separate katakana characters contracted into one sound.',
  icon: 'キョ',
  group: 2,
  characters: {
    キャ: [ 'kya'] ,
    キュ: [ 'kyu'] ,
    キョ: [ 'kyo'] ,
    シャ: [ 'sha'] ,
    シュ: [ 'shu'] ,
    ショ: [ 'sho'] ,
    チャ: [ 'cha'] ,
    チュ: [ 'chu'] ,
    チョ: [ 'cho'] ,
    ニャ: [ 'nya'] ,
    ニュ: [ 'nyu'] ,
    ニョ: [ 'nyo'] ,
    ヒャ: [ 'hya'] ,
    ヒュ: [ 'hyu'] ,
    ヒョ: [ 'hyo'] ,
    ミャ: [ 'mya'] ,
    ミュ: [ 'myu'] ,
    ミョ: [ 'myo'] ,
    リャ: [ 'rya'] ,
    リュ: [ 'ryu'] ,
    リョ: [ 'ryo'] ,
    ギャ: [ 'gya'] ,
    ギュ: [ 'gyu'] ,
    ギョ: [ 'gyo'] ,
    ジャ: [ 'ja'] ,
    ジュ: [ 'ju'] ,
    ジョ: [ 'jo'] ,
    ビャ: [ 'bya'] ,
    ビュ: [ 'byu'] ,
    ビョ: [ 'byo'] ,
    ピャ: [ 'pya'] ,
    ピュ: [ 'pyu'] ,
    ピョ: [ 'pyo'] ,
  }
}

export default KatakanaCombos