const Katakana = {
  name: 'Katakana',
  description: 'A fundamental component of the Japanese writing system used mostly for foreign words.',
  icon: 'カ',
  group: 2,
  characters: {
    ア: [ 'a' ],
    イ: [ 'i' ],
    ウ: [ 'u' ],
    エ: [ 'e' ],
    オ: [ 'o' ],
    カ: [ 'ka' ],
    キ: [ 'ki' ],
    ク: [ 'ku' ],
    ケ: [ 'ke' ],
    コ: [ 'ko' ],
    サ: [ 'sa' ],
    シ: [ 'shi' ],
    ス: [ 'su' ],
    セ: [ 'se' ],
    ソ: [ 'so' ],
    タ: [ 'ta' ],
    チ: [ 'chi' ],
    ツ: [ 'tsu' ],
    テ: [ 'te' ],
    ト: [ 'to' ],
    ナ: [ 'na' ],
    ニ: [ 'ni' ],
    ヌ: [ 'nu' ],
    ネ: [ 'ne' ],
    ノ: [ 'no' ],
    ハ: [ 'ha' ],
    ヒ: [ 'hi' ],
    フ: [ 'fu' ],
    ヘ: [ 'he' ],
    ホ: [ 'ho' ],
    マ: [ 'ma' ],
    ミ: [ 'mi' ],
    ム: [ 'mu' ],
    メ: [ 'me' ],
    モ: [ 'mo' ],
    ヤ: [ 'ya' ],
    ユ: [ 'yu' ],
    ヨ: [ 'yo' ],
    ラ: [ 'ra' ],
    リ: [ 'ri' ],
    ル: [ 'ru' ],
    レ: [ 're' ],
    ロ: [ 'ro' ],
    ワ: [ 'wa' ],
    ヰ: [ 'wi' ],
    ヱ: [ 'we' ],
    ヲ: [ 'wo' ],
    ン: [ 'n' ],
  }
}

export default Katakana