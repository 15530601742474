const HiraganaCombos = {
  name: 'Hiragana Combos',
  description: 'Two separate hiragana characters contracted into one sound.',
  icon: 'ひょ',
  group: 1,
  characters: {
    きゃ: [ 'kya' ],
    きゅ: [ 'kyu' ],
    きょ: [ 'kyo' ],
    しゃ: [ 'sha' ],
    しゅ: [ 'shu' ],
    しょ: [ 'sho' ],
    ちゃ: [ 'cha' ],
    ちゅ: [ 'chu' ],
    ちょ: [ 'cho' ],
    にゃ: [ 'nya' ],
    にゅ: [ 'nyu' ],
    にょ: [ 'nyo' ],
    ひゃ: [ 'hya' ],
    ひゅ: [ 'hyu' ],
    ひょ: [ 'hyo' ],
    みゃ: [ 'mya' ],
    みゅ: [ 'myu' ],
    みょ: [ 'myo' ],
    りゃ: [ 'rya' ],
    りゅ: [ 'ryu' ],
    りょ: [ 'ryo' ],
    ぎゃ: [ 'gya' ],
    ぎゅ: [ 'gyu' ],
    ぎょ: [ 'gyo' ],
    じゃ: [ 'ja' ],
    じゅ: [ 'ju' ],
    じょ: [ 'jo' ],
    びゃ: [ 'bya' ],
    びゅ: [ 'byu' ],
    びょ: [ 'byo' ],
    ぴゃ: [ 'pya' ],
    ぴゅ: [ 'pyu' ],
    ぴょ: [ 'pyo' ],
  }
}

export default HiraganaCombos