const KatakanaDakuten = {
  name: 'Katakana Dakuten/Handakuten',
  description: 'Dakuten and handakuten are marks that indicate a change in pronunciation.',
  icon: 'ガ',
  group: 2,
  characters: {
    ガ: [ 'ga' ],
    ギ: [ 'gi' ],
    グ: [ 'gu' ],
    ゲ: [ 'ge' ],
    ゴ: [ 'go' ],
    ザ: [ 'za' ],
    ジ: [ 'ji' ],
    ズ: [ 'zu' ],
    ゼ: [ 'ze' ],
    ゾ: [ 'zo' ],
    ダ: [ 'da' ],
    ヂ: [ 'ji' ],
    ヅ: [ 'zu' ],
    デ: [ 'de' ],
    ド: [ 'do' ],
    バ: [ 'ba' ],
    ビ: [ 'bi' ],
    ブ: [ 'bu' ],
    ベ: [ 'be' ],
    ボ: [ 'bo' ],
    パ: [ 'pa' ],
    ピ: [ 'pi' ],
    プ: [ 'pu' ],
    ペ: [ 'pe' ],
    ポ: [ 'po' ],
  }
}

export default KatakanaDakuten